<template>
    <transition name="modal" v-if="popupStatus">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <form method="POST" >
                            <div class="modal-header">
                                <h5 class="modal-title">Edit Address</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" @click="showModal = false">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
                                    <div class="uk-form-group" id="address_block">
                                        <label class="uk-form-label"> Address</label>
                                        <div class="uk-position-relative w-100">
                                            <span class="uk-form-icon">
                                                <i class="icon-line-awesome-map-marker"></i>
                                            </span>
                                            <input class="uk-input" maxlength="100" type="text" name="address" placeholder="Enter your address" value="" >
                                        </div>
                                        <p class="error-msg d-none"></p>
                                    </div>
                                    <div class="uk-form-group" id="street_block">
                                        <label class="uk-form-label"> Street</label>
                                        <div class="uk-position-relative w-100">
                                            <span class="uk-form-icon">
                                                <i class="icon-line-awesome-street-view"></i>
                                            </span>
                                            <input class="uk-input" maxlength="100" type="text" name="street" placeholder="Enter your street" value="" >
                                        </div>
                                        <p class="error-msg d-none"></p>
                                    </div>
                                    <div class="uk-form-group" id="city_block">
                                        <label class="uk-form-label"> City</label>
                                        <div class="uk-position-relative w-100">
                                            <span class="uk-form-icon">
                                                <i class="icon-material-outline-location-city"></i>
                                            </span>
                                            <input class="uk-input" maxlength="100" type="text" name="city" placeholder="Enter your city" value="" >
                                        </div>
                                        <p class="error-msg d-none"></p>
                                    </div>
                                    <div class="uk-form-group" id="zip_block">
                                        <label class="uk-form-label"> ZIP</label>
                                        <div class="uk-position-relative w-100">
                                            <span class="uk-form-icon">
                                                <i class="icon-material-outline-my-location"></i>
                                            </span>
                                            <input class="uk-input" maxlength="20" type="tel" name="zip" placeholder="Enter your zip" value="" >
                                        </div>
                                        <p class="error-msg d-none"></p>
                                    </div>
                                    <div class="uk-form-group" id="state_block">
                                        <label class="uk-form-label"> State</label>
                                        <div class="uk-position-relative w-100">
                                            <span class="uk-form-icon">
                                                <i class="icon-material-outline-location-on"></i>
                                            </span>
                                            <input class="uk-input" maxlength="100" type="text" name="state" placeholder="Enter your state" value="" >
                                        </div>
                                        <p class="error-msg d-none"></p>
                                    </div>
                                    <div class="uk-form-group" id="country_block">
                                        <label class="uk-form-label"> Country</label>
                                        <div class="uk-position-relative w-100">
                                            <span class="uk-form-icon">
                                                <i class="icon-feather-globe"></i>
                                            </span>
                                            <input class="uk-input" maxlength="50" type="text" name="country" placeholder="Enter your country" value="" >
                                        </div>
                                        <p class="error-msg d-none"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click="popupStatus = false">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        action: Boolean
    },
    data(){
        return {
            popupStatus: false
        }
    },
    created: function() {
        console.log(this.$props.action)
    },
    watch: {
        "$props.action": function(){
            console.log(this.$props.action)
            this.popupStatus = this.$props.action
        }
    }
}
</script>

